// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

body {
    font-family: "Intern", sans-serif;
    font-size: 1rem;
}

.navbar-dark {
    background-color: #5faccd;
    color: white;
}

.h-1 {
    height: 1em;
}

div#jumbotron-welcome {
    background-image: url("../images/header.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 30rem;
    padding-bottom: 20rem;
    text-shadow: 0px 0px 1px #505050;
    color: white;
    position: relative;
    z-index: -2;
    min-height: 50vh;
    border-radius: 0;

    @include media-breakpoint-down(sm) {
        background-size: 100% auto;
        padding: 0;
        min-height: 53vw;
    }
}

div#jumbotron-welcome .container {
    max-width: 1360px;
}

.bg-lblue {
    background-color: $lblue;
}

.text-nblue {
    color: #5faccd;
}

section#contact {
    font-size: 1.2em !important;
    ul {
        list-style-type: none; /* Remove bullets */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margins */
    }
}

div#map {
    min-height: 400px;
}
